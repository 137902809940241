<template>
    <div class="card bs-5 mb-5">
        <div class="fl-te-c pb-3">
            <h4 v-html="'TRAINING'"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$router.push({path: '/training/add/'})" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template class="text-center" #disable="{rowData}">
                <checkbox-input v-model="rowData.disable" @input="disable(rowData)"></checkbox-input>
            </template>
            <template #active="{rowData}">
                <checkbox-input v-model="rowData.active" @input="changeStatus(rowData)"></checkbox-input>
            </template>
            <template #image="{rowData}">
                <img :src="prefix + rowData.image" width="150px" alt="img">
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn size="xs" icon="fa fa-plus" color="success" text="Details" @click="schedule(rowData)"/>
                    <delete-btn @click="setDelete(rowData)"/>
                    <edit-btn @click="setEdit(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Edit Training" ref="trainingEditModal" :no-close-on-backdrop="true" width="70vw" header-color="primary">
            <EditTraining :initial-data="editingItem" @success="formSuccess"></EditTraining>
        </modal>

        <delete-modal ref="trainingDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Training <b v-html="deletingItem && deletingItem.title"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../../data/urls';
import EditTraining from './EditTraining';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';

export default {
    name       : 'EventTraining',
    components : { EditTraining },
    computed   : { ...mapGetters(['currentUser']) },
    data () {
        return {
            prefix       : '',
            listUrl      : urls.admin.event.training.list,
            deleteUrl    : urls.admin.event.training.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name       : 'course_id',
                    sortField  : 'course_id',
                    title      : 'Course id',
                    titleClass : ''
                },
                {
                    name       : 'title',
                    sortField  : 'title',
                    title      : 'Title',
                    titleClass : ''
                },
                {
                    name       : 'class_type',
                    sortField  : 'class_type',
                    title      : 'Class Type',
                    titleClass : ''
                },
                {
                    name       : 'course_fee',
                    sortField  : 'course_fee',
                    title      : 'Course Fee',
                    titleClass : ''
                },
                {
                    name       : 'total_fee',
                    sortField  : 'total_fee',
                    title      : 'Total Fee',
                    titleClass : ''
                },
                {
                    name       : '__slot:disable',
                    sortField  : 'disable',
                    title      : 'Disabled in Events Page',
                    titleClass : ''
                },
                {
                    name       : '__slot:active',
                    sortField  : 'active',
                    title      : 'Display in website',
                    titleClass : ''
                },
                {
                    name       : '__slot:image',
                    sortField  : 'image',
                    title      : 'Image',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        changeStatus (item) {
            const that = this;
            item.api_key = this.currentUser.api_key;
            axios.form(urls.admin.event.training.activateDeactivateWebsite, item).then(function (response) {
                const json = response.data;
                console.log('json', json);
                if (json.error === false) {
                    that.$notify('Successfully Updated Status..!', 'Success',
                        {
                            type : 'success'
                        });
                } else {
                    console.log('json.errors', json.errors);
                    that.$notify(json.message, 'Message',
                        {
                            type : 'warning'
                        });
                    item.active = !item.active;
                }
            }).catch(function (err) {
                item.active = !item.active;
                console.log('error : ', err);
                that.$notify('Something went wrong..!', 'Success',
                    {
                        type : 'warning'
                    });
            });
        },
        formSuccess () {
            const refs = this.$refs;
            refs.trainingEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.trainingEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.trainingDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Training..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.trainingDeleteModal.close();
            this.$refs.table.refreshTable();
        },

        schedule (item) {
            this.$router.push({ path : '/training/' + item.id + '/details/' });
        },

        disable (item) {
            const that = this;
            axios.form(urls.admin.event.training.activateDeactivate, item).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify(json.message, 'Success',
                        {
                            type : 'success'
                        });
                    // if (json.activate === false) {
                    //     that.$router.push({ path : '/training/' + item.id + '/details/' });
                    // } else {}
                } else {
                    that.$notify(json.message, 'Error',
                        {
                            type : 'warning'
                        });
                    item.disable = !item.disable;
                }
            }).catch(function (err) {
                item.disable = !item.disable;
                console.log('error : ', err);
                that.$notify('Something went wrong..!', 'Success',
                    {
                        type : 'warning'
                    });
            });
        }
    }
};
</script>

<style scoped>

</style>
